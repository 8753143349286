/***************************************\

Newsletter data format:
{
  edition: "xxx期 - yyyymm",
  eVersion: "link to newsletters"
},

**************************************/

const newsletters = [
  {
    year: "2020",
    issues: [
      {
        edition: "253期 - 20221`",
        eVersion: "https://mailchi.mp/3b879ec0963f/202211",
      },
      {
        edition: "252期 - 202210",
        eVersion: "https://mailchi.mp/c66f8476f16e/202210",
      },
      {
        edition: "251期 - 202209",
        eVersion: "https://mailchi.mp/c541448d8d8a/202209",
      },
      {
        edition: "250期 - 202207",
        eVersion: "https://mailchi.mp/e42c0f744af4/202207",
      },
      {
        edition: "249期 - 202206",
        eVersion: "https://mailchi.mp/1b4d00b1dc88/20220506",
      },
      {
        edition: "248期 - 202204",
        eVersion: "https://mailchi.mp/525a687c55b5/202204",
      },
      {
        edition: "247期 - 202203",
        eVersion: "https://us16.campaign-archive.com/?u=897dc7c90180bbc11f67a62b7&id=2c540fbe1b",
      },
      {
        edition: "246期 - 202202",
        eVersion: "https://mailchi.mp/433534382c0c/202202",
      },
      {
        edition: "245期 - 202201",
        eVersion: "https://mailchi.mp/a3605d7d8e16/202201-13417873",
      },
    ]
  },
  {
    year: "2021",
    issues: [
      {
        edition: "244期 - 202112",
        eVersion: "https://mailchi.mp/ec2c6d8a328d/202112",
      },
      {
        edition: "243期 - 202111",
        eVersion: "https://mailchi.mp/229e2ed8d8fc/202111",
      },
      {
        edition: "242期 - 202109",
        eVersion: "https://mailchi.mp/2ab3e7ec6dba/202109",
      },
      {
        edition: "241期 - 202107",
        eVersion: "https://mailchi.mp/24aaa47350cd/202107_no241",
      },
      {
        edition: "240期 - 202106",
        eVersion: "https://mailchi.mp/061c801002e1/202105_no239-13395001",
      },
      {
        edition: "239期 - 202105",
        eVersion: "https://mailchi.mp/8e37bacd3932/202105_no239-1",
      },
      {
        edition: "238期 - 202104",
        eVersion: "https://mailchi.mp/cd1df31e721e/202104_no238",
      },
      {
        edition: "237期 - 202103",
        eVersion: "http://eepurl.com/hroNbz",
      },
      {
        edition: "236期 - 202101",
        eVersion: "http://eepurl.com/hnmd2f",
      },
    ]
  },
  {
    year: "2020",
    issues: [
      {
        edition: "235期 - 202012",
        eVersion: "https://mailchi.mp/39a0e6434a6b/202012_no235",
      },
      {
        edition: "234期 - 202011",
        eVersion: "https://mailchi.mp/fca46214bdd2/202011_no234-1417037",
      },
      {
        edition: "233期 - 202010",
        eVersion: "http://eepurl.com/heCXib",
      },
      {
        edition: "232期 - 202009",
        eVersion: "https://mailchi.mp/4576c045bd4e/202008_no231",
      },
      {
        edition: "231期 - 202008",
        eVersion: "https://mailchi.mp/c5137bc2e822/202008_no231",
      },
      {
        edition: "230期 - 202007",
        eVersion: "https://mailchi.mp/42d74bfcc387/202007_no230-678613",
      },
      {
        edition: "229期 - 202006",
        eVersion: "https://mailchi.mp/cad8a076a37d/202006_no229",
      },
      {
        edition: "228期 - 202005",
        eVersion: "http://eepurl.com/g07HDz",
      },
      {
        edition: "227期 - 202004",
        eVersion: "http://eepurl.com/g0A50L",
      },
      {
        edition: "223期 - 202001",
        eVersion: "http://eepurl.com/gOuluj",
      },
    ]
  },
  {
    year: "2019",
    issues: [
      {
        edition: "222期 - 201912",
        eVersion: "https://mailchi.mp/bb98f43be028/201912_no222",
      },
      {
        edition: "221期 - 201911",
        eVersion: "https://mailchi.mp/42bde2072085/201911_no221",
      },
      {
        edition: "220期 - 201910",
        eVersion: "https://mailchi.mp/bb86a0742f23/201910_no220",
      },
      {
        edition: "219期 - 201909",
        eVersion: "https://mailchi.mp/cf87900a179f/201909_no219",
      },
      {
        edition: "218期 - 201907",
        eVersion: "http://eepurl.com/gvzZVf",
      },
      {
        edition: "217期 - 201906",
        eVersion: "https://mailchi.mp/3ab227eaf883/201906_no217",
      },
      {
        edition: "216期 - 201905",
        eVersion: "https://mailchi.mp/93d984668d5d/201905_no216",
      },
      {
        edition: "215期 - 201904",
        eVersion: "https://mailchi.mp/5074e3475564/201903_no214-493977",
      },
      {
        edition: "214期 - 201903",
        eVersion: "http://eepurl.com/gjdZd1",
      },
      {
        edition: "213期 - 201902",
        eVersion: "https://mailchi.mp/9c2b54b5f532/201902_no-213",
      },
      {
        edition: "212期 - 201901",
        eVersion: "https://mailchi.mp/a07c73651fb1/201901_no-212",
      },
    ]
  },
  {
    year: "2018",
    issues: [
      {
        edition: "211期 - 201812",
        eVersion: "https://mailchi.mp/049d7ec903a9/201812_no-211",
      },
      {
        edition: "210期 - 201811",
        eVersion: "https://mailchi.mp/f8eb33a2ed46/201811_no-210",
      },
      {
        edition: "209期 - 201810",
        eVersion: "https://mailchi.mp/3fe03bf6f6d8/201810_no-209",
      },
      {
        edition: "208期 - 201809",
        eVersion: "https://mailchi.mp/db674062927f/201806_no208",
      },
      {
        edition: "207期 - 201806",
        eVersion: "https://mailchi.mp/e2f0232f9b59/201806_no207",
      },
      {
        edition: "206期 - 201805",
        eVersion: "http://mailchi.mp/4297ceaced03/201805_no206",
      },
      {
        edition: "205期 - 201804",
        eVersion: "https://mailchi.mp/bef89593a224/201804_no205",
      },
      {
        edition: "204期 - 201803",
        eVersion: "http://eepurl.com/dk4flT",
      },
      {
        edition: "203期 - 201801",
        eVersion: "http://eepurl.com/df4wKv",
      },
    ]
  },
  {
    year: "2017",
    issues: [
      {
        edition: "202期 - 201712",
        eVersion: "http://mailchi.mp/62e094127e72/201712_no202",
      },
      {
        edition: "201期 - 201711",
        eVersion: "http://mailchi.mp/7a067b15c67b/201711_no201",
      },
      {
        edition: "200期 - 201710",
        eVersion: "http://mailchi.mp/c598f8e8e1b1/201710_no200",
      },
    ]
  }
];

module.exports = newsletters;