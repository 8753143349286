import React from 'react'
import { graphql } from 'gatsby'

import Seo from 'components/SEO'
import PageContent from 'components/Shared/_PageContent'
import Title from 'components/Shared/Title'
import IFrame from 'components/Shared/IFrame'

import newsletters from 'constant/member-newsletters.constant'

import styled from 'styled-components'
const Desc = styled.div`
  p {
    margin-bottom: 0;
    font-size: calc(var(--font-size-default) * 0.9);
  }
  span {
    color: var(--color-primary);
    font-weight: 400;
  }
`

const Newsletter = ({ data }) => {
  const { seo } = data
  const { title, description } = seo.frontmatter.newsletters

  return (
    <PageContent>
      <Seo title={title} description={description} />
      <Title>晶報</Title>
      <Desc>
        <p>
          為確保每月按時收到「晶報」電子報，請您把 <span>crystalchoirnews@gmail.com </span>
          加入信箱聯絡人資料
        </p>
        <h4>最新一期晶報: {newsletters[0].issues[0].edition}</h4>
      </Desc>
      <IFrame
        title='newsletters'
        src={newsletters[0].issues[0].eVersion}
        height='295vh'
      />
    </PageContent>
  )
}

export default Newsletter

export const query = graphql`
  {
    seo: mdx(frontmatter: { type: { eq: "seo_member" } }) {
      frontmatter {
        newsletters {
          title
          description
        }
      }
    }
  }
`
